export const authMenu = {
	login: {
		id: 'login',
		text: 'Login',
		path: '/login',
		hide: true,
	},
	joinTeam: {
		id: 'accept_invitation',
		text: 'Join Our Team',
		path: '/accept_invitation',
		hide: true,
	},
	forgetGroup: {
		id: 'forget',
		text: 'Reset Password',
		hide: true,
		subMenu: {
			forgetPassword: {
				id: 'forgetPassword',
				text: 'Get Help',
				path: '/forgot-password',
				hide: true,
			},
			confirmation: {
				id: 'confirmation',
				text: 'Verify your account',
				path: '/verify-account',
				hide: true,
			},
			resetPassword: {
				id: 'reset',
				text: 'Reset your password now',
				path: '/reset-password',
				hide: true,
			},
		},
	},
};

export const dashboardMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/',
		icon: 'Dashboard',
		permission: null,
	},
	ussd: {
		id: 'ussd',
		text: 'USSD Applications',
		path: '/ussd',
		icon: 'CloudUpload',
		permission: 'view loans',
	},
	users: {
		id: 'users',
		text: 'Users',
		path: '/users',
		icon: 'Person',
		permission: 'view users',
	},
	agents: {
		id: 'agents',
		text: 'Agents',
		path: '/agents',
		icon: 'PersonPinCircle',
		permission: 'view agents',
	},
	notifications: {
		id: 'notifications',
		text: 'Notifications',
		path: '/notifications',
		icon: 'PhoneIphone',
		permission: 'view notifications',
	},
	sms: {
		id: 'sms',
		text: 'SMS',
		path: '/sms',
		icon: 'Message',
		permission: 'view sms',
	},
	upload: {
		id: 'uploadSalary',
		text: 'Upload Salary',
		path: '/upload-salary',
		icon: 'FileUpload',
		permission: 'upload salary',
	},
	uploadSalaryLists: {
		id: 'uploadSalaryLists',
		text: 'Upload History',
		path: '/upload-history',
		icon: 'History',
		permission: 'upload salary',
	},
	reports: {
		id: 'reports',
		text: 'Reports',
		path: '/reports',
		icon: 'FileArrowDown',
		permission: 'view reports',
	},
};

export const profileMenu = {
	profile: {
		id: 'profile',
		text: 'Profile',
		path: '/profile',
		icon: 'Person',
		hide: true,
	},
};

export const loansMenu = {
	loans: {
		id: 'loans',
		text: 'Loan records',
		icon: 'Activity',
		permission: 'view loans',
		path: '/loans',
	},
};

export const management = {
	management: {
		id: 'management',
		text: 'Management',
		icon: 'Earbuds',
		permission: 'view management',
	},
	roles: {
		id: 'roles',
		text: 'Roles',
		icon: 'Earbuds',
		path: '/roles',
		permission: 'view roles',
	},
	administrators: {
		id: 'admins',
		text: 'Administrators',
		icon: 'FollowTheSigns',
		path: '/administrators',
		permission: 'view administrators',
	},
};
