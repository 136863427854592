import moment from 'moment';

export const datFormat = (date) => {
	return moment(date).fromNow();
};

export const timeFromMinutes = (startTime, endTime) => {
	const now = moment(startTime);
	const end = moment(endTime);
	const duration = moment.duration(now.diff(end));
	return duration.asMinutes();
};

export const datFormat2 = (date) => {
	return moment(date).format('Do MMMM, YYYY');
};

export const datFormat3 = (date) => {
	return moment(date).format('Do MMMM, YYYY hh:mma');
};

export const datFormat4 = (date) => {
	return moment(date).format('hh:mm A');
};

export function strPaddingStart(number, digits = 2, emptyDigit = 0) {
	let length = 0;
	let n = Math.abs(number);
	const absoluteNumber = n;
	do {
		n /= 10;
		// eslint-disable-next-line no-plusplus
		length++;
	} while (n >= 1);
	const prefix = Array(Math.max(digits - length + 1, 0)).join(emptyDigit);
	return number < 0 ? `-${prefix}${absoluteNumber}` : prefix + number;
}

export function capitalizeFirstLetter(str) {
	// eslint-disable-next-line prefer-template
	return `${str}`.replace(/^([a-z])|\s+([a-z])/g, ($1) => $1.toUpperCase());
}

export function numberWithCommas(x) {
	return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
}

export function formatRole(role) {
	const val = role.split('---');
	return val[0];
}

export function formatAmount(amount, currency) {
	return amount.toLocaleString(currency.toLowerCase() === 'usd' ? 'en-US' : 'en-NG', {
		style: 'currency',
		currency: currency.toUpperCase(),
	});
}

export function ordinalSuffixOf(i) {
	const j = i % 10;
	const k = i % 100;
	if (j === 1 && k !== 11) {
		return `${i}st`;
	}
	if (j === 2 && k !== 12) {
		return `${i}nd`;
	}
	if (j === 3 && k !== 13) {
		return `${i}rd`;
	}
	return `${i}th`;
}

export function getRoi(amount, startDate, endDate, interestType, interest) {
	let resp = 0;
	const type = interestType.toLowerCase();
	const days = endDate.diff(startDate, 'days');
	const roi = (interest / 100) * amount;
	if (type === 'daily') {
		resp += roi * days;
	} else if (type === 'weekly') {
		const weeks = days / 7;
		resp += roi * weeks;
	} else if (type === 'monthly') {
		const months = days / 30;
		resp += roi * months;
	} else if (type === 'quarterly') {
		const quarter = days / 90;
		resp += roi * quarter;
	} else if (type === 'annually') {
		const annual = days / 365;
		resp += roi * annual;
	}
	return resp;
}

export function getRoiRate(amount, startDate, endDate, interestType, interest) {
	const roi = getRoi(amount, startDate, endDate, interestType, interest);
	const resp = (roi / amount) * 100;
	return resp;
}
