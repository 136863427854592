import React, { lazy } from 'react';
import { authMenu, dashboardMenu, loansMenu, management, profileMenu } from '../menu';

const AUTH = {
	LOGIN: lazy(() => import('../pages/auth/Login')),
	FORGET_PASSWORD: lazy(() => import('../pages/auth/forget/Forget')),
	CONFIRM_RESET: lazy(() => import('../pages/auth/forget/ConfirmForget')),
	RESET: lazy(() => import('../pages/auth/forget/ResetPassword')),
	JOIN_TEAM: lazy(() => import('../pages/auth/JoinTeam')),
};

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/dashboard/DashboardPage')),
	USSDPAGE: lazy(() => import('../pages/dashboard/UssdPage')),
	AGENTS: {
		INDEX: lazy(() => import('../pages/user/agents/AgentIndex')),
		SHOW_AGENT: lazy(() => import('../pages/user/agents/AgentSingle')),
	},
	CUSTOMERS: {
		INDEX: lazy(() => import('../pages/user/customers/CustomerIndex')),
		SHOW_CUSTOMER: lazy(() => import('../pages/user/customers/CustomerSingle')),
	},
	NOTIFICATIONS: lazy(() => import('../pages/notifications/NotificationIndex')),
	SMS: lazy(() => import('../pages/notifications/SendSms')),
	UPLOAD_SALARY: lazy(() => import('../pages/user/customers/UploadSalaryNow')),
	SALARY_INDEX: lazy(() => import('../pages/user/customers/SalaryHistory')),
	REPORT: lazy(() => import('../pages/reports/Report')),
};

const PROFILE = {
	PROFILE: lazy(() => import('../pages/profile/Profile')),
};

const LOAN_MENU = {
	PUBLIC_LOAN: lazy(() => import('../pages/Loans/PublicIndex')),
	PUBLIC_SINGLE: lazy(() => import('../pages/Loans/PublicSingle')),
};

const ADMINISTRATIONS = {
	ROLES: lazy(() => import('../pages/administration/Role')),
	EDIT_ROLE: lazy(() => import('../pages/administration/EditRole')),
	STAFF: lazy(() => import('../pages/administration/Administrators')),
	PROFILE: lazy(() => import('../pages/profile/Profile')),
};

const presentation = [
	/**
	 * Auth
	 */
	{
		path: authMenu.login.path,
		element: <AUTH.LOGIN />,
		exact: true,
	},
	{
		path: authMenu.forgetGroup.subMenu.forgetPassword.path,
		element: <AUTH.FORGET_PASSWORD />,
		exact: true,
	},
	{
		path: `${authMenu.joinTeam.path}/:slug`,
		element: <AUTH.JOIN_TEAM />,
		exact: true,
	},
	{
		path: authMenu.forgetGroup.subMenu.confirmation.path,
		element: <AUTH.CONFIRM_RESET />,
		exact: true,
	},
	{
		path: authMenu.forgetGroup.subMenu.resetPassword.path,
		element: <AUTH.RESET />,
		exact: true,
	},
	/**
	 * Landing
	 */
	{
		path: dashboardMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
		exact: true,
	},
	{
		path: dashboardMenu.ussd.path,
		element: <LANDING.USSDPAGE />,
		exact: true,
	},
	{
		path: dashboardMenu.agents.path,
		element: <LANDING.AGENTS.INDEX />,
		exact: true,
	},
	{
		path: `${dashboardMenu.agents.path}/:id`,
		element: <LANDING.AGENTS.SHOW_AGENT />,
		exact: true,
	},
	{
		path: dashboardMenu.users.path,
		element: <LANDING.CUSTOMERS.INDEX />,
		exact: true,
	},
	{
		path: `${dashboardMenu.users.path}/:id`,
		element: <LANDING.CUSTOMERS.SHOW_CUSTOMER />,
		exact: true,
	},
	{
		path: dashboardMenu.notifications.path,
		element: <LANDING.NOTIFICATIONS />,
		exact: true,
	},
	{
		path: dashboardMenu.sms.path,
		element: <LANDING.SMS />,
		exact: true,
	},
	{
		path: dashboardMenu.upload.path,
		element: <LANDING.UPLOAD_SALARY />,
		exact: true,
	},
	{
		path: dashboardMenu.uploadSalaryLists.path,
		element: <LANDING.SALARY_INDEX />,
		exact: true,
	},
	{
		path: dashboardMenu.reports.path,
		element: <LANDING.REPORT />,
		exact: true,
	},
	/**
	 * Profile
	 */
	{
		path: profileMenu.profile.path,
		element: <PROFILE.PROFILE />,
		exact: true,
	},
	/**
	 * Loan Menu
	 */
	{
		path: loansMenu.loans.path,
		element: <LOAN_MENU.PUBLIC_LOAN />,
		exact: true,
	},
	{
		path: `${loansMenu.loans.path}/:id`,
		element: <LOAN_MENU.PUBLIC_SINGLE />,
		exact: true,
	},
	/**
	 * Administration
	 */
	{
		path: management.roles.path,
		element: <ADMINISTRATIONS.ROLES />,
		exact: true,
	},
	{
		path: management.administrators.path,
		element: <ADMINISTRATIONS.STAFF />,
		exact: true,
	},
	{
		path: `${management.roles.path}/edit/:id`,
		element: <ADMINISTRATIONS.EDIT_ROLE />,
		exact: true,
	},
	{
		path: `/profile`,
		element: <ADMINISTRATIONS.PROFILE />,
		exact: true,
	},
];
const contents = [...presentation];

export default contents;
